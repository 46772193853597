import React, { useState } from "react";
import "./navbar.css"
import { Link } from 'react-scroll'
import contactImg from "../../Assets/contactme.png"
import Lottie from "react-lottie";
import contactme from "../../Assets/contactme.json";
import menu from "../../Assets/menu.json";

const Navbar = ()=>{

    const [shoMenu, setShowMenu] = useState(false);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: contactme,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      const menuoption = {
        loop: true,
        autoplay: true,
        animationData: menu,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

    return (
        <nav className="navbar">
            <div className="navname">
            <h2 className="fname">Chandan</h2>{' '}
            <h2 className="lname">&nbsp;Rai</h2>

            </div>
            
            <div className="nevMenu">
                <Link activeClass="active" to="intro" spy={true} smooth='easeInCubic' offset={-200} duration={500} className="navMenuListItem">Home</Link>
                <Link activeClass="active" to="skills" spy={true} smooth='easeInCubic' offset={-150} duration={500} className="navMenuListItem">About</Link>
                <Link activeClass="active" to="competence" spy={true} smooth='easeInCubic' offset={-100} duration={500} className="navMenuListItem">Competence</Link>
                <Link activeClass="active" to="companies" spy={true} smooth='easeInCubic' offset={-100} duration={500} className="navMenuListItem">Companies</Link>
   

            </div>
            {/* <button className="contactButton" onClick={()=>{
                document.getElementById('contact').scrollIntoView({behaviour : 'smooth'});
            }}>
                <img src={contactImg} alt="" className="navbconatactimg" /> Contact Me
            </button> */}
            <div className="contactMe" onClick={()=>{
                document.getElementById('contact').scrollIntoView({behaviour : 'smooth'});
            }}>
        <Lottie options={defaultOptions} height={60} width={60} />
           </div>

           {/* Mobile view */}

           <div className="menuImg" onClick={()=>{setShowMenu(!shoMenu)}}>
        <Lottie options={menuoption} height={60} width={60} />
           </div>
            
            <div className="mobMenu" style={{display : shoMenu?'flex':'none'}}>
                <Link activeClass="active" to="intro" spy={true} smooth='easeInCubic' offset={-200} duration={500} className="listItem" onClick={()=>{setShowMenu(false)}}>Home</Link>
                <Link activeClass="active" to="skills" spy={true} smooth='easeInCubic' offset={-150} duration={500} className="listItem" onClick={()=>{setShowMenu(false)}}>About</Link>
                <Link activeClass="active" to="competence" spy={true} smooth='easeInCubic' offset={-100} duration={500} className="listItem" onClick={()=>{setShowMenu(false)}}>Competence</Link>
                <Link activeClass="active" to="companies" spy={true} smooth='easeInCubic' offset={-100} duration={500} className="listItem" onClick={()=>{setShowMenu(false)}}>Companies</Link>
                <Link activeClass="active" to="contactPage" spy={true} smooth='easeInCubic' offset={-100} duration={500} className="listItem" onClick={()=>{setShowMenu(false)}}>Contact Me</Link>


            </div>
           

        </nav>
    )

}
export default Navbar;