import React from "react";
import profileGif from "../../Assets/sd.gif";
import "./intro.css";
import codechef from "../../Assets/codechef.svg";
import linkedin from "../../Assets/linkedin.svg";
import github from "../../Assets/githubsvg.svg";
import Typewriter from "typewriter-effect";
// import { RiGithubFill, RiLinkedinFill} from "react-icons/ri";
// import { SiCodechef } from "react-icons/si"
import { Link } from "react-scroll";
import Lottie from "react-lottie";
import animationData from "../../Assets/seaniamte.json";
import { motion } from "framer-motion";

const Intro = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.1,
        duration: 2,
       
       
      },
    },
  };
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <motion.section
      variants={container}
      initial="hidden"
      animate="visible"
      id="intro"
    >
      <motion.div variants={item} className="introContent">
        <span className="hello">Hello,</span>
        <span className="introText">
          {" "}
          <span className="salutation">
            I'm &nbsp;
            <span className="introName">
              <Typewriter
                options={{ strings: ["Chandan"], autoStart: true, loop: true }}
              />
            </span>
          </span>
          Software Engineer
        </span>

        <p className="introPara">
          I am a skilled Software Engineer with over 4.3 years of experience in
          industry. I love tech and coding.
        </p>
        <div className="socialbuttons">
          <Link
            className="github"
            aria-label="Github Repo"
            href="https://github.com/chanstefvi"
            target="_blank"
          >
            <img src={github} alt="" className="githubimg" />
            <div></div>
          </Link>
          <Link
            className="linkedin"
            aria-label="linkedin"
            href="https://www.linkedin.com/in/chandan-rai-044a10162/"
            target="_blank"
          >
            <img src={linkedin} alt="" className="linkedinimg" />
          </Link>
          <Link
            className="codechef"
            aria-label="codechef"
            href="https://www.codechef.com/users/chandan_rai"
            target="_blank"
          >
            <img src={codechef} alt="" className="codechefimg" />
          </Link>
        </div>
      </motion.div>
      {/* <img src={profileGif} alt="profileGif" className="profileGif" /> */}
      <motion.div variants={item} className="profileGif">
        <Lottie options={defaultOptions} height={400} width={400} />
      </motion.div>
    </motion.section>
  );
};

export default Intro;
