
import React, { useRef } from "react";
import "./skills.css"
import cloud from "../../Assets/cloud.png"
import webdevpng from "../../Assets/webdevpng.png"
import sap from "../../Assets/sap.svg";
import { motion, useScroll, useTransform } from "framer-motion";


const Skills = () =>{
     const refs = useRef(null);
    const { scrollYProgress } =useScroll({
        target:refs,
        offset:["0 1","1.33 1"]
    });
    const scaleProgress=useTransform(scrollYProgress,[0,1],[0.8,1]);
    const opacityProgress=useTransform(scrollYProgress,[0,1],[0.6,1]);
    return (
       <motion.section ref={refs} style={{scale:scaleProgress, opacity:opacityProgress}} id='skills' >
        <span className="skillTitle">What I do</span>
        <span className="skillDesc"> I am currently working as SAP Integration Suite Developer.I love to code and resolve problems. </span>
        <div className="skillBars">
            <div className="skillBar">
                <img src={cloud} alt="clud" className="skillBarImg" />
                <div className="skillBarText">
                    <h2> SAP Integration Suite

                    </h2>
                    <p>Integration developer with knowledge of integrating many onpremise and cloud system.</p>

                </div>
            </div>

            <div className="skillBar">
                <img src={webdevpng} alt="MERN" className="skillBarImg" />
                <div className="skillBarText">
                    <h2>
                        MERN Full Stack Developer

                    </h2>
                    <p>Knowledge on React,Node,Mongodb,and Expressjs</p>

                </div>
            </div>
            <div className="skillBar">
                <img src={sap} alt="SAP BTP" className="skillBarImg" />
                <div className="skillBarText">
                    <h2>
                        SAP BTP

                    </h2>
                    <p></p>

                </div>
            </div>

        </div>
       </motion.section>
    )
}

export default Skills;