import React, { useRef, useState } from "react";
import "./contact.css";
import sap from "../../Assets/sap.svg";
import bcone from "../../Assets/bcone.png";
import tcs from "../../Assets/TCS.png";
import { color, motion, useScroll, useTransform } from "framer-motion";
import emailjs from "@emailjs/browser";
import validator from "validator";
// import dotenv from "dotenv";

// dotenv.config({path: '../../../.env.local'})
const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const Contact = () => {
  const refs = useRef(null);
  const form = useRef();
  const mailid = process.env.REACT_APP_MAILSID;
  const temid = process.env.REACT_APP_MAILTEMPID;
  const mailkey = process.env.REACT_APP_MAILPKEY;

  //email validator

  const [emailError, setEmailError] = useState('')
  const validateEmail = (e) => {
    var email = e.target.value
  
    if (validator.isEmail(email)) {
      setEmailError('')
    } else {
      setEmailError('Enter valid Email!')
    }
  }

  // mailjs part
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(mailid, temid, form.current, mailkey).then(
      (result) => {
        e.target.reset();
        alert("Email Sent !");
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  const { scrollYProgress } = useScroll({
    target: refs,
    offset: ["0 1", "1.33 1"],
  });
  const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.8, 1]);
  const opacityProgress = useTransform(scrollYProgress, [0, 1], [0.6, 1]);
  return (
    <motion.section
      ref={refs}
      style={{ scale: scaleProgress, opacity: opacityProgress }}
      id="contactPage"
    >
      <div id="companies">
        <h1 className="contactPageTitle">Companies worked for</h1>
        <p className="companiesDesc">
          I had the opportunity to work for below companies:
        </p>
        <div className="companiesImgs">
          <img src={sap} alt="TCS" className="compImg" />
          <img src={bcone} alt="Bristlecone" className="compImg" />
          <img src={tcs} alt="SAP" className="compImg" />
        </div>
      </div>
      <div id="contact">
        <h1 className="contactPageTitle">Contact Me</h1>
        <span className="contactDesc">
          Please fill the form to reach out to me.
        </span>
        <form className="contactForm" ref={form} onSubmit={sendEmail}>
          <input
            name="user_name"
            type="text"
            className="name"
            placeholder="Your name"
          />
          <input
            name="user_email"
            id="userEmail"
            type="text"
            className="email"
            placeholder="Your email"
           
            onChange={(e) => validateEmail(e)}
          />
          <br />
          <span style={{
          fontWeight: 'bold',
          color: "#fc7703",
        }}>{emailError}</span>

          <textarea
            name="message"
            placeholder="Your Message"
            className="msg"
            rows="5"
          ></textarea>

          <button className="submitBtn" type="submit" value="send">
            Submit
          </button>
        </form>
      </div>
    </motion.section>
  );
};

export default Contact;
