import React from "react";
import Navbar from "./Components/Header/navbar";
import Intro from "./Components/Intro/intro";
import Skills from "./Components/skills/skills";
import Competence from "./Components/Competence/competence";
import Contact from "./Components/Contact/contact";
import Footer from "./Components/Footer/footer";

// import { motion, useScroll, useTransform } from "framer-motion";



function App() {
  return (
    <div className="App">
      <Navbar />
      <Intro/>
      <Skills />
      <Competence />
      <Contact />
      <Footer />
      
    </div>
  );
}

export default App;
