import React from "react";
import "./footer.css"

const Footer = ()=>{
    return(
        <footer className="footer">
       &#169; Copyright 2023 Chandan Rai. All right reserved.
        </footer>
    )

}
export default Footer;