import React,  { useRef } from "react";
import "./competence.css";
import cpp from "../../Assets/c++.svg"
import cloud from "../../Assets/cloud.png"
import js from "../../Assets/js.svg"
import mern from "../../Assets/mern.png"
import sapIS from "../../Assets/sapIS.svg"
import sap from "../../Assets/sap.svg"
import ds from "../../Assets/datascience.png"
import blockchain from "../../Assets/blockchain.png"
import { motion, useScroll, useTransform } from "framer-motion";


const Competence = ()=>{
    const refs = useRef(null);
    const { scrollYProgress } =useScroll({
        target:refs,
        offset:["0 1","1.33 1"]
    });
    const scaleProgress=useTransform(scrollYProgress,[0,1],[0.8,1]);
    const opacityProgress=useTransform(scrollYProgress,[0,1],[0.6,1]);
    return(
        <motion.section ref={refs} style={{scale:scaleProgress, opacity:opacityProgress}} id="competence">
           
            <span className="competenceTitle">Skills I Possess</span>
            <div className="competenceBlocks">
                <div className="competenceBlock">
                 <img src={cpp} alt="cpp" className="competenceBlockImg" />
                 <div className="compText">
                 <h2> C++</h2>
                 </div>
                 

                </div>
                <div className="competenceBlock">
                 <img src={js} alt="javascript" className="competenceBlockImg" />
                 <div className="compText">
                 <h2> JavaScript</h2>
                 </div>

                </div>
                <div className="competenceBlock">
                 <img src={mern} alt="MERN" className="competenceBlockImg" />
                 <div className="compText">
                 <h2> MERN</h2>
                 </div>

                </div>
                <div className="competenceBlock">
                 <img src={sapIS} alt="SAP Integration Suite" className="competenceBlockImg" />
                 <div className="compText">
                 <h2> SAP IS</h2>
                 </div>

                </div>
                <div className="competenceBlock">
                 <img src={sap} alt="SAP BTP" className="competenceBlockImg" />
                 <div className="compText">
                 <h2> SAP BTP</h2>
                 </div>

                </div>
            </div>
            <span className="competenceTitle">Skills In Learning Phase</span>
            <div className="competenceBlocks">
                <div className="competenceBlock">
                 <img src={ds} alt="Data Science" className="competenceBlockImg" />
                 <div className="compText">
                 <h2> Data Science</h2>
                 </div>
                 

                </div>
                <div className="competenceBlock">
                 <img src={blockchain} alt="blockchain" className="competenceBlockImg" />
                 <div className="compText">
                 <h2> Blockchain</h2>
                 </div>

                </div>
                

            
                
            </div>
        </motion.section>
    )
}

export default Competence;